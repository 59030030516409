.table-permissions strong {
  font-size: 1rem !important;
  font-weight: 500;
}
.table-container {
  overflow-x: auto;
}
[dir] .checkbox-permission {
  cursor: pointer;
}
.checkbox-permission label {
  font-weight: 400;
}
.list-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
.custom-checkout-roles-default .custom-control-label {
  font-size: 0.9rem !important;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.custom-checkout-roles-default .custom-control-label span {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}